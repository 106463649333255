import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import Quizz from "../components/Quizz/Quizz";

import { useAuth } from "@/modules/auth/AuthContext";
import Carousel from "@/modules/common/components/Carousel";
import TitledPage from "@/modules/common/components/layout/pages/TitledPage";
import SolutionCard from "@/modules/common/components/SolutionCard";
import check from "@/modules/home/assets/check.jpg";
import iva from "@/modules/home/assets/iva.jpg";
import pdf_saude from "@/modules/home/assets/saude.pdf";
import saude from "@/modules/home/assets/saude.png";
import pdf_topofmind from "@/modules/home/assets/top-of-mind.pdf";
import topofmind from "@/modules/home/assets/top-of-mind.png";
import tracking from "@/modules/home/assets/tracking.jpg";
import views from "@/modules/home/assets/views.jpg";

const carouselItems = [
  {
    title: "Como anda a saúde dos brasileiros?",
    description:
      "A resolução de ano novo por aí foi relacionada à saúde?! Confira algumas taxas que podem te alertar a criar novos hábitos.",
    imgPath: saude,
    onClick: () => {
      window.open(pdf_saude);
    },
  },
  {
    title: "Qual marca está na cabeça dos brasileiros?",
    description:
      "Confira a resposta dos brasileiros sobre qual marca eles lembram na hora de comprar eletrônicos, artigos esportivos, livros, calçados e mais…",
    imgPath: topofmind,
    onClick: () => {
      window.open(pdf_topofmind);
    },
  },
];

const Home = () => {
  const { user } = useAuth();
  const userFirstName = user?.name.split(" ")[0] ?? "";

  return (
    <TitledPage title={`${t("Hi")}, ${userFirstName}`}>
      <Grid container spacing={3} sx={{ mb: "40px" }}>
        <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
          <Box sx={{ flexGrow: 1, minHeight: "320px", maxWidth: "100%" }}>
            <Carousel items={carouselItems} />
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Quizz />
        </Grid>
      </Grid>
      <Stack sx={{ pb: 5 }}>
        <Stack direction="row" sx={{ mb: "12px" }}>
          <Typography
            sx={{
              flexGrow: 1,
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            <Trans>Discover our solutions</Trans>
          </Typography>
          <Link to="/products">
            <Button variant="text" size="small">
              <Trans>See all</Trans>
            </Button>
          </Link>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} xl={3}>
            <SolutionCard
              badge="Inteligencia competitiva"
              title="Pesquisas de Comportamento"
              description="Compreenda o seu consumidor e dialogue com ele a partir de dados e informações reais."
              imgPath={check}
              onClickLearnMore={() => {
                window.open(
                  "https://pinion.app/empresas-pesquisas-b2b/pesquisas-de-comportamento/"
                );
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <SolutionCard
              badge="Inteligencia competitiva"
              title="Pesquisas de Concorrência"
              description="Colete e monitore dados do mercado para identificar padrões e prever futuras direções."
              imgPath={iva}
              onClickLearnMore={() => {
                window.open(
                  "https://pinion.app/empresas-pesquisas-b2b/pesquisas-de-concorrencia/"
                );
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <SolutionCard
              badge="Inteligencia competitiva"
              title="Pesquisas de Publicidade"
              description="Teste antes de lançar! Examine performance e aceitação considerando percepções reais do seu público-alvo."
              imgPath={tracking}
              onClickLearnMore={() => {
                window.open(
                  "https://pinion.app/empresas-pesquisas-b2b/pesquisas-de-publicidade/"
                );
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <SolutionCard
              badge="Inteligencia competitiva"
              title="Pesquisas de Marca e Produto"
              description="Compreenda o seu consumidor e dialogue com ele a partir de dados e informações reais."
              imgPath={views}
              onClickLearnMore={() => {
                window.open(
                  "https://pinion.app/empresas-pesquisas-b2b/pesquisas-de-marca-e-produto/"
                );
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </TitledPage>
  );
};

export default Home;
